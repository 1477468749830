<template>
    <UniversalTable 
        :model="model"
        :pageName="page_name"
        tableType="tasks_to_sprint"
        autoHeight
        useSelect
        endpoint="/tasks/sprint/task/list/"
        :params="queryParams"
        :openHandler="openTask"
        :main="false"
        taskType="task"
        showChildren
        extendDrawer
        :hash="false"
        @rowSelected="rowSelected" />
</template>

<script>
import UniversalTable from '@/components/TableWidgets/UniversalTable'
export default {
    components: {
        UniversalTable
    },
    props: {
        page_name: {
            type: String,
            required: true
        },
        model: {
            type: String,
            required: true
        },
        queryParams: {
            type: Object,
            default: () => {}
        },
        rowSelected: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        openTask(item) {
            const query = Object.assign({}, this.$route.query)
            if(query.task && Number(query.task) !== item.id || !query.task) {
                this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 999999)
                query.task = item.id
                this.$router.push({query})
            }
        }
    }
}
</script>